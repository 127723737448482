<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <!-- <div class="form-row mb-6">
      <div class="inline-block mr-5" v-bind:class="{ active: currentTab == 1 }">
        <label class="cursor-pointer" @click="selectTab(1)">unit view</label>
      </div>
      <div class="inline-block ml-5" v-bind:class="{ active: currentTab == 2 }">
        <label class="cursor-pointer" @click="selectTab(2)">resident view</label>
      </div>
    </div> -->
    <div class="form-row w-full">
      <button
        class="transcript text-gray-500 focus:outline-none font-semibold py-2 px-4 border button-color"
        v-bind:class="{ activeCatg: currentTab == 1 }"
        @click="selectTab(1)">
        unit view
      </button>
      <button
        class="mismatchtext-gray-500 focus:outline-none font-semibold py-2 px-4 border button-color"
        v-bind:class="{ activeCatg: currentTab == 2 }"
        @click="selectTab(2)">
        resident view
      </button>
    </div>
    <div class="flex-1 flex-col overflow-auto">
      <div v-if="currentTab === 1" class="flex-1 flex-col overflow-auto">
        <List
          :fields="unitFields"
          :resource="'getAllUnits'"
          ref="list_unit"
          :pageSize="18"
          trackBy="unit"
          :search="true"
          :clickable="true"
          :isCommunityRequired="false"
          :initial-filter="filter"
          :filter-mapper="mapFilterToQuery"
          @rowData="getDetails"
        >

          <template v-slot:filters="props">
            <div class="form-row">
                <div class="form-col">
                  <SelectInput
                      name="statusIds"
                      label="status"
                      :options="statuses"
                      multiple
                  />
                </div>
            </div>
            <div class="form-row justify-end">
              <button @click.stop="props.reset()" class="btn-transparent mr-8">Reset</button>
              <button class="btn-primary">Filter</button>
            </div>
          </template>

          </List>
        </div>
        <div v-else>
          <List
            :fields="residentFields"
            :resource="'getAllResidents'"
            ref="list_resident"
            :pageSize="18"
            trackBy="unit"
            :search="true"
            :clickable="true"
            :isCommunityRequired="false"
            :initial-filter="filter"
            :filter-mapper="mapFilterToQuery"
            @rowData="getDetails"
          >

          <template v-slot:filters="props">
            <div class="form-row">
                <div class="form-col">
                  <SelectInput
                      name="statusIds"
                      label="status"
                      :options="statuses"
                      multiple
                  />
                </div>
            </div>
            <div class="form-row justify-end">
              <button @click.stop="props.reset()" class="btn-transparent mr-8">Reset</button>
              <button class="btn-primary">Filter</button>
            </div>
          </template>

          </List>
        </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin';
import ModalNavigation from '@/mixins/ModalNavigation';
import Loader from '@/components/ui/Loader';
import List from '@/components/move_in/List';
import SelectInput from "@/components/form/SelectInput";

export default {
  name: 'ManageResidents',
  mixins: [
    AuthMixin,
    ModalNavigation
  ],
  components: {
    Loader,
    List,
    SelectInput,
  },
  data(){
    return{
      loading: false,
      currentTab: 1,
      filter: {
          // moveInDate: {
          //    startDate: null,
          //    endDate: null,
          // }
          statusIds: null
      },
       statuses: [{
          key: 'active',
          value: 'active'
        },
        {
          key: 'future',
          value: 'future'
        },
        {
          key: 'on notice',
          value: 'on notice'
        },
      ],
      unitFields: [
        {
            name: 'unit',
            title: 'unit',
            sortField: 'unit'
        },
        {
            name: 'statusName',
            title: 'status',
            sortField: 'status'
        },
        {
            name: 'lease_exp',
            title: 'lease exp',
            sortField: 'lease_exp'
        },
        {
            name: 'move_in',
            title: 'move in',
            sortField: 'move_in'
        },
        {
            name: 'move_out',
            title: 'move out',
            sortField: 'move_out'
        },
        {
            name: 'balance',
            title: 'balance',
            sortField: 'balance'
        },
        {
          name: 'resident',
          title: 'resident',
          sortField: 'resident',
          formatter: (values) => {
            let data = '';

            values.slice(0,3).forEach((resident) => {
                data += `<span class="border-gray-400 border-2 p-1 mr-3">${resident.name}</span>` ;
            });

            if(values.length > 3) data +=`+ ${values.length-3}`

            return data
          },
        },
      ],
      residentFields: [
        {
            name: 'resident',
            title: 'resident',
            sortField: 'resident'
        },
        {
            name: 'unit',
            title: 'unit',
            sortField: 'unit'
        },
        {
            name: 'statusName',
            title: 'status',
            sortField: 'status'
        },
        {
            name: 'lease_exp',
            title: 'lease exp',
            sortField: 'lease_exp'
        },
        {
            name: 'move_in',
            title: 'move in',
            sortField: 'move_in'
        },
        {
            name: 'move_out',
            title: 'move out',
            sortField: 'move_in'
        },
        {
            name: 'balance',
            title: 'balance',
            sortField: 'balance'
        },
      ],

    }
  },
  methods: {
      mapFilterToQuery(values) {
         // const { from: lastMoveInFrom, to: lastMoveInTo } = this.getFilterDateRange(values.moveInDate);
         const statusIds =  values.statusIds
         return {
           statusIds,
         }
      },
    selectTab(nextTab) {
      if (this.currentTab !== nextTab) {
        this.currentTab = nextTab;
      }
    },
    getDetails(){
      this.$router.push({name: 'manage_residents.resident-details'});
    }
  },
  mounted(){
    this.selectTab(this.$route.params.isResident ? 2 : 1);
  }
}
</script>


<style scoped>
  .form {
    width: 55%;
  }
  .label-container {
    width: 53%;
  }
  .active {
    color: var(--highlightColor500);
    border-bottom: 2px solid;
    border-color: var(--highlightColor500);
    margin: 0 5px 0 5px;
  }
  .activeCatg {
    background-color: var(--highlightColor500);
    @apply text-white border-transparent;
  }
</style>
